<script setup>

import Header from '../../partials/HeaderAlternate.vue'
import OrderSection from "../../partials/OrderSection.vue";
import DocumentSection from "../../partials/DocumentSection.vue";


import Footer from '../../partials/Footer.vue'
import {onMounted, reactive, ref } from "vue";
import {usePage} from "@inertiajs/inertia-vue3";
import Steps from '@/Components/Steps.vue';


const props = defineProps({
  documentProp: {
    type: Object,
    required: false,
    default: null
  },
  productProp: {
    type: Object,
    required: false,
    default: null
  },
  loading: {
    type: Boolean,
    required: false,
    default: false
  },

})
const currentStep = ref(usePage().props.value.documentProp? 2 : 1);

const companyDocument = ref(usePage().props.value.documentProp);

const product = ref(usePage().props.value.productProp);



const updateCurrentStep = (step) => {
  currentStep.value =   step;
}

const getDocumentInfo = (documentInfo) => {
  currentStep.value =   2;
  companyDocument.value =   documentInfo;
}

onMounted(() => {

});

</script>

<template>
  <div className="flex flex-col min-h-screen overflow-hidden">
    <!-- Site header -->
    <Header class="min-h-[125px]"/>
    <!-- Page content -->
    <main class="grow">
      <Steps :currentStepProp="currentStep" @update-current-step="updateCurrentStep"></Steps>
      <div v-if="currentStep == 1"><DocumentSection :documentNumberProp="companyDocument" @select-document-number="getDocumentInfo"></DocumentSection></div>
      <div v-if="currentStep == 2" class="bg-gray-50">
      <OrderSection :companyDocumentProp="companyDocument"  :productProp="product"></OrderSection>
      </div>
    </main>
    <!-- Site footer -->
    <Footer/>
  </div>
</template>

